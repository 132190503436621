<!--suppress ALL -->
<template>
  <div>
    <el-drawer
        :before-close='beforeClose'
        :destroy-on-close='true'
        :show-close='true'
        :size="'60%'"
        :visible.sync='drawerVisible'
        :wrapperClosable='false'
        :with-header='true'
    >
      <template slot='title'>
        <div class='clearfix' slot='header'>
          <span class='title'>{{ computedType.label }}项目{{ textBtn }}</span>
        </div>
      </template>
      <el-card class='box-card'>
        <div>
          <el-form :model='form' label-width='140px' ref='form'>
            <el-form-item label='项目名称' required>
              <el-input style='width: 100%' clearable v-model='form.name'></el-input>
            </el-form-item>
            <el-form-item label='意向达人' v-if='computedType.showFields["is_assign"]'>
              <el-row>
                <el-col :span='3'>
                  <el-switch
                      v-model='is_assign'
                      active-color='#13ce66'
                      inactive-color='#ff4949'
                      :active-value='1'
                      active-text='是'
                      inactive-text='否'
                      :inactive-value='0'>
                  </el-switch>
                </el-col>
                <el-col :span='21'>
                  <ArtistSearch ref='ArtistSearch' style='width: 100%' @handleSelect='chooseAdArtist'
                                v-if='is_assign===1'/>
                </el-col>
              </el-row>
              <el-row v-if='is_assign===1'>
                <el-tag
                    :key='tag.id'
                    :type='tag.id'
                    @close='tagClose(index)'
                    closable
                    style='margin-left: 3px'
                    v-for='(tag,index) in intentions'>
                  {{ tag.nickname }}
                </el-tag>
              </el-row>
            </el-form-item>
            <el-row>
              <el-col :span='12'>
                <el-form-item label='品牌' required>
                  <el-select allow-create filterable @change="handleChangeBrand" style='width: 100%'
                             clearable v-model='brand' placeholder='请选择'>
                    <el-option
                        v-for='item in brandOptions'
                        :key='item.id'
                        :label='item.name'
                        :value='item.id'>
                    </el-option>
                  </el-select>

                  <!--            <i-->
                  <!--                    @click="clickBtn"-->
                  <!--                    class="el-icon-search"-->
                  <!--                    slot="prefix">-->
                  <!--            </i>-->
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='所属集团'>
                  <el-select filterable style='width: 100%' v-model='form.group_id' placeholder='请选择'>
                    <el-option
                        v-for='item in belongGroup'
                        :key='item.id'
                        :label='item.display_name'
                        :value='item.id'>
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label='品牌背书' style='width: 830px'>
                  <el-input :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='1000' show-word-limit
                            placeholder='请输入品牌背书'
                            type='textarea' v-model='form.brand_intro'></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label='产品图片'>
                  <ApeUploader :limit='1' :upload-file-list='uploadFileList'
                               @handleUploadRemove='handleUploadRemove'
                               @handleUploadSuccess='handleUploadSuccess'
                               uploadTip='请上传图片，最多上传1份。大小限制2M文件'></ApeUploader>
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='产品'>
                  <productSearch v-if="showEditProduct" :name.sync='product' @handleSelect='selectProduct'
                                 style='display: inline'/>
                  <span title="修改产品信息" style="cursor: pointer" @click="editProduct" v-else> {{ form.product }} <i
                      class="el-icon-edit"></i> </span>
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='是否寄回'>
                  <el-switch active-color='#13ce66'
                             :active-value="1"
                             :inactive-value="2"
                             inactive-text='否' active-text='是' inactive-color='#ff4949'
                             v-model='form.is_send_back'></el-switch>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span='24'>
                <el-form-item label='产品卖点'>
                  <el-input :autosize='{ minRows: 6, maxRows: 10}' clearable maxlength='200'
                            placeholder='品牌背景/产品售卖链接等,字数限制：200' show-word-limit type='textarea'
                            v-model='form.intro'></el-input>
                </el-form-item>
              </el-col>
              <el-col :span='12'>
                <el-form-item label='附件上传'>
                  <!--                                    <el-button>点击上传</el-button>-->
                  <el-upload
                      :before-upload='beforeUpload'
                      :headers='uploadHeaders'
                      :on-change='handleChange'
                      :on-success='importSuccess'
                      :show-file-list='false'
                      action='/admin_api/upload/file'
                      class='upload-demo'
                      multiple
                      :file-list='attachments'
                  >
                    <el-button icon='el-icon-upload' type='primary'>点击上传</el-button>
                  </el-upload>
                  <template v-if='attachments?.length>0||false'>
                    <span v-for='(item,index) in attachments' :key='item.id'>
                       <a :href='item.full_path' target='_blank'>{{ item.original || '附件' }} </a>
                      <i class='el-icon-delete' style='color: red;margin: 0 5px;cursor: pointer'
                         @click='deleteFile(item?.id,index)'></i>
                    </span>
                  </template>
                  <!--                  <br> <span style="color:darkgray ">支持扩展名：.doc .docx .pdf</span>-->
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item required :label="this.typeMap[this.categoryType].type===1?'合作平台':'直播平台'">
              <el-checkbox-group v-model='form.platforms'>
                <el-checkbox :key="index+'category'" :label='item.name' v-for='(item,index) in platforms'>
                  {{ item.display_name }}
                </el-checkbox>
              </el-checkbox-group>
              <el-radio-group v-model='form.package' v-if="form.platforms?.includes('package')">
                <el-radio :key="index+'package'" :label='item?.name'
                          v-for='(item,index) in packageData'>
                  {{ item.display_name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if='computedType.showFields["coop_id"]' label='合作形式'>
              <el-radio-group v-model='form.coop_id'>
                <el-radio :key="index+'category'" :label='item.id'
                          v-for='(item,index) in category'>
                  {{ item.display_name }}
                </el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label='平台下单'>
              <el-switch
                  inactive-text='否' active-text='是' active-color='#13ce66'
                  inactive-color='#ff4949'
                  :active-value="1"
                  :inactive-value="2"
                  v-model='form.is_platform_pay'>
              </el-switch>
            </el-form-item>
            <div class='row-switch'>
              <el-form-item label='是否保量'>
                <el-switch
                    inactive-text='否' active-text='是' active-color='#13ce66'
                    inactive-color='#ff4949' :active-value='1' :inactive-value='2'
                    v-model='form.is_quantity'>
                </el-switch>
              </el-form-item>
              <el-form-item label='费比' required style='width: 50%' v-if='form.is_quantity===1'>
                <el-input size='mini' v-model='form.qty_policy' clearable maxlength='7' show-word-limit
                          placeholder="例：保2+20%"></el-input>
              </el-form-item>
              <el-form-item label='直播佣金' required style='width: 50%' v-if='form.is_quantity===1'>
                <el-input size='mini' v-model='form.live_cmn' clearable maxlength='7' show-word-limit
                          placeholder="直播佣金"></el-input>
              </el-form-item>
            </div>
            <template v-if='computedType.type===2'>
              <el-form-item label='直播机制' required>
                <el-input :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='200' show-word-limit
                          placeholder='直播机制'
                          type='textarea' v-model='form.live_mec'></el-input>
              </el-form-item>

              <div class='row-switch'>
                <el-form-item label='有无特别激励政策'>
                  <el-switch
                      inactive-text='否' active-text='是' active-color='#13ce66'
                      inactive-color='#ff4949' :active-value='1' :inactive-value='2'
                      v-model='form.is_policy'>
                  </el-switch>
                </el-form-item>
                <el-form-item required label='激励政策' style='width: 100%' v-if='form.is_policy===1'>
                  <el-input size='mini' v-model='form.cart_mechanism' clearable maxlength='200'
                            show-word-limit></el-input>
                </el-form-item>
              </div>
            </template>
            <template v-if='computedType.showFields["is_cart"]'>
              <el-form-item label='挂车佣金'>
                <el-row>
                  <el-col :span='3'>
                    <el-switch
                        active-color='#13ce66'
                        :active-value="1"
                        :inactive-value="2"
                        inactive-text='否' active-text='是' inactive-color='#ff4949'
                        v-model='form.is_cart'>
                    </el-switch>
                  </el-col>
                  <el-col :span='10'>
                    <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" style='width: 100%'
                              type='number'
                              v-if='form.is_cart===1' v-model='form.cart_rate'>
                      <template slot='append'>%</template>
                    </el-input>
                  </el-col>
                  <el-col :span='10' v-if='form.is_cart===1'>
                    <el-form-item label='挂车机制' style='width: 100%'>
                      <el-input v-model='form.cart_mechanism'></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form-item>
            </template>
            <el-form-item label='授权平台' :required="form.is_auth_price===1?true:false">
              <el-row>
                <el-col :span='3'>
                  <el-switch
                      active-color='#13ce66'
                      :active-value="1"
                      :inactive-value="2"
                      inactive-text='否' active-text='是' inactive-color='#ff4949'
                      v-model='form.is_auth_price'>
                  </el-switch>
                </el-col>
                <el-col :span='21'>
                  <el-select v-model='form.auth_platform' style='width: 100%' placeholder='请选择' multiple
                             v-if='form.is_auth_price===1'>
                    <el-option
                        v-for='item in authPlatform'
                        :key='item.id'
                        :label='item.display_name'
                        :value='item.id'>
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label='可否签保价一个月'>
              <el-switch
                  inactive-text='否' active-text='是' active-color='#13ce66'
                  inactive-color='#ff4949'
                  :active-value="1"
                  :inactive-value="2"
                  v-model='form.support_value'>
              </el-switch>
            </el-form-item>
            <el-form-item label='是否全网统一机制'>
              <el-switch
                  inactive-text='否' active-text='是' active-color='#13ce66'
                  inactive-color='#ff4949'
                  :active-value="1"
                  :inactive-value="2"
                  v-model='form.unify'>
              </el-switch>
            </el-form-item>
            <el-form-item v-if="form.unify===2" label='特殊机制说明' style='width: 830px'>
              <el-input :autosize='{ minRows: 3, maxRows: 10}' clearable maxlength='500' show-word-limit
                        placeholder='特殊机制说明'
                        type='textarea' v-model='form.special_remark'></el-input>
            </el-form-item>
            <el-form-item label='是否有签约独家'>
              <el-switch
                  inactive-text='否' active-text='是' active-color='#13ce66'
                  inactive-color='#ff4949'
                  :active-value="1"
                  :inactive-value="2"
                  v-model='form.exclusive'>
              </el-switch>
            </el-form-item>
            <el-form-item v-if="form.exclusive===1" label='独家机制' style='width: 830px'>
              <el-input :autosize='{ minRows: 3, maxRows: 10}' clearable maxlength='500' show-word-limit
                        placeholder='独家机制说明'
                        type='textarea' v-model='form.other_activity'></el-input>
            </el-form-item>
            <el-form-item label='期望档期' required>
              <el-date-picker
                  :picker-options='pickerOptions'
                  align='right'
                  end-placeholder='结束日期'
                  range-separator='至'
                  start-placeholder='开始日期'
                  type='daterange'
                  v-model='expect_time'
                  value-format='yyyy-MM-dd'>
              </el-date-picker>
            </el-form-item>
            <el-form-item label='直播明细' style='width: 830px'
                          :required='form.coop_id===11052?true:false||!isNormalInquiry'>
              <el-input :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='1000' show-word-limit
                        placeholder='是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等'
                        type='textarea' v-model='form.live_detail'></el-input>
            </el-form-item>
            <el-form-item label='其他要求' style='width: 830px'>
              <el-input :autosize='{ minRows: 8, maxRows: 10}' clearable maxlength='1000' show-word-limit
                        placeholder='是否走平台/排竞时长/视频保留时长/产品露出时间/是否上脸使用/是否寄送等'
                        type='textarea' v-model='form.other_desc'></el-input>
            </el-form-item>
            <el-form-item label='报名时间' required>
              <el-date-picker
                  :picker-options='pickerOptions'
                  align='right'
                  end-placeholder='结束日期'
                  range-separator='至'
                  start-placeholder='开始日期'
                  type='datetimerange'
                  v-model='apply_time'
                  value-format='yyyy-MM-dd HH:mm:ss'>
              </el-date-picker>
            </el-form-item>
            <el-form-item v-if="userPermissions.indexOf('inquiry_update_all') !== -1" label='创建人'>
              <el-select allow-create filterable style='width: 42%'
                         clearable v-model='form.created_by' placeholder='请选择创建人'>
                <el-option
                    v-for='item in userOptions'
                    :key='item.id'
                    :label='item.name'
                    :value='item.id'>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item style="text-align: center">
              <el-button size="medium" @click='onSubmit' type='primary'>{{ textBtn }}</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="this.rowId !== 'add'&&form.is_apply!==2">
          <el-row>
            <el-col :offset='9' :span='3' v-if='form.is_apply!==1'>
              <el-button @click='handleVoid(1)' round type='success'>开始报名</el-button>
            </el-col>
            <el-col :offset='9' :span='3' v-if='form.is_apply===1'>
              <el-button @click='handleVoid(3)' round type='warning'>结束报名</el-button>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </el-drawer>
  </div>
</template>

<script>
import productSearch from './components/productSearch.vue'
import ArtistSearch from '@/components/artist/ArtistSearch'

import { getToken } from '@/utils/auth'
import { mapGetters } from 'vuex'
import ApeUploader from '@/components/ApeUploader.vue'

const shortcuts = [{
  text: '最近一周',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
    picker.$emit('pick', [end, start])
  }
}, {
  text: '最近一个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
    picker.$emit('pick', [end, start])
  }
}, {
  text: '最近三个月',
  onClick(picker) {
    const end = new Date()
    const start = new Date()
    start.setTime(start.getTime() + 3600 * 1000 * 24 * 90)
    picker.$emit('pick', [end, start])
  }
}
]

export default {
  name: 'CreateEdit',
  data() {
    return {
      form: { type: null, auth_platform: [] },
      expect_time: [],//期望日期
      apply_time: [],//报名时间
      platforms: [],//开通平台列表
      opened_platforms: [],//选择的平台
      category: [],//合作形式
      packageData: [],//打包平台
      belongGroup: [],//所属集团
      authPlatform: [],//授权平台
      textBtn: '创建',
      convertData: ['is_platform_pay', 'is_cart', 'is_auth_price'],
      intentions: [],
      uploadHeaders: { 'HYPERF-SESSION-ID': null },
      is_assign: 0,
      pickerOptions: { shortcuts },
      attachments: [],
      original_name: [],
      typeMap: {
        'normal': {
          type: 1,
          label: '普通询单',
          // showFields: ['is_assign', 'skill_id']
          showFields: { is_assign: true, coop_id: true, is_cart: true }
        },
        'year': {
          type: 2,
          label: '年框大全案',
          showFields: ['live_mec', 'is_cart']
        }
      },
      userOptions: [],
      brandOptions: [],
      brand: null,
      productOptions: [],
      product: null,
      showEditProduct: true,
      uploadFileList: []
    }
  },
  components: { ApeUploader, productSearch, ArtistSearch },
  props: {
    rowId: {
      type: [Number, String],
      default() {
        return 'add'
      }
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false
      }
    },
    categoryType: {
      type: String,
      default: 'normal'
    }
  },
  watch: {
    drawerVisible: {
      deep: true,
      handler() {
        this.infoData()
      }
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    computedType() {
      return this.typeMap[this.categoryType]
    },
    isNormalInquiry() {
      return this.computedType.type === 1
    }
  },
  methods: {
    async deleteFile(val, index) {
      if (this.rowId !== 'add') {
        this.$confirm(`确定是否删除附件`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          this.attachments.splice(index, 1)
          let id = await this.$api.deleteInquiryFile({ id: val })
          if (id) {
            this.$notify.success('删除成功')
          }
        })
      } else {
        this.attachments.splice(index, 1)
      }
    },
    beforeUpload() {
      this.uploadLoading = true
      this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
    },
    //下载上传模块
    handleChange(file) {
      this.file = [file]
    },
    importSuccess(response) {
      if (response.code === 200) {
        this.$notify.success('上传成功')
        this.attachments.push({
          full_path: response?.data[0]?.full_path,
          original: response.data[0].original,
          attachment_id: response.data[0].id
        })
      } else {
        this.$notify.warning(response.msg)
      }
    },
    async handleVoid(status) {
      // "status": 2 //1:进行中 2:已完结 3:已作废
      //is_apply 报名状态 //1：报名中 2:不可报名，3.结束报名
      let statusData = {
        id: this.rowId,
        status: 1,
        is_apply: status
      }
      let id = await this.$api.switchInquiryStatus(statusData)
      if (id) {
        this.$notify.success(`${status === 1 ? '【开始报名切换】' : '【结束报名切换】'}成功`)
        this.$emit('update:drawerVisible', false)
        await this.infoData()
      }
    },
    chooseAdArtist(item) {
      // JSON.stringify(item) === '{}' ? this.form.artist_id = [] : this.form.artist_id = [item.id]

      if (item && !(JSON.stringify(item) === '{}')) {
        let includes = this.intentions.find((i) => {
          return i.id === item.id
        })
        if (!includes) {
          this.intentions.push({ nickname: item.nickname, id: item.id })
        }
      }
      this.$refs['ArtistSearch'].clearData()
    },
    tagClose(val) {
      this.intentions.splice(val)
    },
    beforeClose() {
      this.$emit('update:drawerVisible', false)
    },
    async infoData() {
      //合作形式
      let data = await this.$api.getCategoryListByIdentify(`inquiry-coop`)
      //合作平台
      let { list } = await this.$api.getCategoryListByIdentify(`inquiry-platforms`)
      //打包数据
      // let pack = await this.$api.getCategoryListByIdentify(`package`)
      //所属集团
      let group = await this.$api.getCategoryListByIdentify(`belong-group`)
      let authPlatform = await this.$api.getCategoryListByIdentify(`auth-platform`)
      this.authPlatform = authPlatform.list
      list.forEach((item) => {
        if (item.display_name === '打包') {
          this.packageData = item.children
        }
      })
      this.belongGroup = group.list
      if (!this.isNormalInquiry) {
        //如果是全案，直播平台只有抖音和小红书
        list = list.map((item) => {
          return item.name === 'douyin' || item.name === 'xiaohongshu' ? item : null
        }).filter(Boolean)
      }
      this.platforms = list
      this.category = data.list
      if (this.rowId !== 'add') {
        //这里是修改的逻辑
        let { info } = await this.$api.infoInquiryProject({ id: this.rowId })
        this.product = info.product
        this.brand = info.brand_id || info.brand
        this.uploadFileList = info.cover_info ? [{ url: info.cover_info }] : []

        this.showEditProduct = false
        this.$nextTick(() => {
          this.expect_time = [info['expect_start'], info['except_end']]
          this.apply_time = [info['apply_start'], info['apply_end']]
          this.intentions = info['intentions']
          this.is_assign = info['is_assign']
          // this.convertData.forEach((i) => {
          //   info[i] = info[i] !== 1
          // })
          this.attachments = info['attachments']
          // info['auth_platform'] = info['auth_platform']?.id
          // info['is_send_back'] = info['is_send_back'] === 1
          this.form = info
          this.form.platforms.forEach((item, index) => {
            if (item === this.form.package) {
              // delete item
              this.form.platforms.splice(index, 1)
            }
          })

        })
        this.textBtn = '修改'
      } else {
        //报名时间/期望档期/意向达人/附件 - 初始化
        this.expect_time = []
        this.apply_time = []
        this.is_assign = 0
        this.intentions = []
        this.attachments = []
        this.uploadFileList = []
        this.form = {
          type: null,
          name: '',
          brand: '',
          product: '',
          intro: '',
          platforms: [],
          is_platform_pay: false,
          coop_id: '',
          is_send_back: false,
          is_cart: false,
          cart_rate: 0,
          is_auth_price: false,
          auth_platform: [],
          expect_start: '',
          except_end: '',
          apply_end: '',
          apply_start: '',
          is_assign: 0,
          original_name: '',
          other_desc: '',
          package: '',
          cart_mechanism: '',
          attachments: [],
          live_detail: '',
          live_mec: null,
          is_quantity: null,
          qty_policy: null,
          live_cmn: null,
          is_policy: null,
          policy: null,
          unify: 2,
          support_value: 2,
          exclusive: 2
        }
        this.textBtn = '创建'
      }
    },
    editProduct() {
      this.product = this.form.product
      this.showEditProduct = true
    },
    formDataFilter() {

      let returnData = true
      //在新增的时候转一下 因为默认是true/false
      let newValue = { ...this.form }

      // returnData = false
      //期望档期
      if (this.expect_time?.length > 0) {
        [newValue.expect_start, newValue.except_end] = this.expect_time
      } else {
        this.$notify.warning('请选择期望档期')
        returnData = false
      }
      if (this.apply_time?.length > 0) {
        [newValue.apply_start, newValue.apply_end] = this.apply_time
      } else {
        this.$notify.warning('请选择报名时间')
        returnData = false
      }
      if ((this.form.coop_id === 11052 || !this.isNormalInquiry) && !this.form.live_detail) {
        this.$notify.warning('合作形式为全案时,直播明细必填')
        returnData = false
      }
      if (!this.isNormalInquiry && !this.form.live_mec) {
        this.$notify.warning('直播机制必填')
        returnData = false
      }
      if (this.form.platforms?.length <= 0) {
        this.$notify.warning('合作/直播平台必填')
        returnData = false
      }
      if (this.form.is_quantity === 1 && !this.form.qty_policy) {
        this.$notify.warning('费比必填')
        returnData = false
      }
      if (this.form.is_quantity === 1 && !this.form.live_cmn) {
        this.$notify.warning('直播佣金必填')
        returnData = false
      }
      if (this.form.is_policy === 1 && !this.form.cart_mechanism) {
        this.$notify.warning('激励政策必填')
        returnData = false
      }
      if (this.form.is_auth_price === 1 && this.form.auth_platform.length <= 0) {
        this.$notify.warning('授权平台必选')
        returnData = false
      }

      if (this.form.is_quantity == 2) {
        newValue.qty_policy = null
        newValue.live_cmn = null
      }
      //初始化内容
      !(newValue['is_cart']) || newValue['is_cart'] === 1 ? '' : newValue['cart_rate'] = 0
      let nickIds = []
      //意向达人
      newValue.is_assign = this.is_assign
      if (newValue.is_assign === 1) {
        if (this.intentions?.length > 0) {
          this.intentions.forEach((item) => {
            nickIds.push(item.id)
          })
          newValue.intentions = nickIds
        } else {
          this.$notify({
            title: '警告',
            message: '请选择意向达人',
            type: 'warning'
          })
          returnData = false
        }
      } else if (newValue.is_assign === 0) {
        newValue.intentions = []
      }
      //项目名：
      if (newValue.name === '') {
        this.$notify({
          title: '警告',
          message: '项目名必填',
          type: 'warning'
        })
        returnData = false
      }
      //附件上传
      this.attachments?.length > 0 ? newValue.attachments = this.attachments : []
      newValue.platforms.includes('package') ? newValue.platforms.push(newValue?.package) : ''
      if (this.rowId !== 'add') {
        this.form.attachments = []
        newValue['attachments'].forEach((item) => {
          this.form.attachments.push({
            full_path: item?.full_path,
            original: item.original,
            attachment_id: item.attachment_id
          })
        })
        delete this.form.group
      }
      this.form['is_auth_price'] === 2 ? delete this.form['auth_platform'] : this.form['auth_platform']
      returnData ? this.form = { ...this.form, ...newValue } : ''
      return returnData
    },
    async onSubmit() {
      if (this.formDataFilter()) {
        this.form.type = this.typeMap[this.categoryType].type
        // this.form.type = this.isNormalInquiry ? 1 : 2
        if (this.rowId === 'add') {
          let id = await this.$api.saveInquiryProject(this.form)
          if (id) {
            this.$notify.success('添加成功')
            this.$emit('update:drawerVisible', false)
          }
        } else {
          this.form.id = this.rowId
          let id = await this.$api.updateInquiryProject(this.form)
          if (id) {
            this.$notify.success('修改成功')
            this.$emit('update:drawerVisible', false)
          }
        }
      }
    },
    async getBrand() {
      if (this.brandOptions.length === 0) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
      }
    },
    async getUser() {
      if (this.userOptions.length === 0) {
        let { list } = await this.$api.getUserOption()
        this.userOptions = list
      }
    },
    handleChangeBrand() {
      const data = this.brandOptions.map((item) => {
        return this.brand === item.id ? item : false
      }).filter(Boolean)
      if (data.length > 0) {
        this.form.brand = data[0].name
        this.form.brand_id = data[0].id
      } else {
        this.form.brand = this.brand
        this.form.brand_id = 0
      }
    },
    async selectProduct(item) {
      console.log(item)
      if (item && !(JSON.stringify(item) === '{}')) {
        this.form.product_id = item.id
        this.form.product = item.text
        this.form.cover_info = [item.cover_url]
        this.uploadFileList = [{ url: item.cover_url }]
        this.form.brand_intro = item.intro
        this.form.support_value = item.support_value
        this.form.unify = item.unify
        this.form.special_remark = item.special_remark
        this.form.exclusive = item.exclusive
        this.form.other_activity = item.other_activity
      } else {
        this.form.product_id = 0
        this.form.product = this.product
      }
    },
    // 图片删除回调
    handleUploadRemove() {
      this.formData.cover_url = 'http://tisu-pd.oss-cn-hangzhou.aliyuncs.com/platform_beta/20220719/T20220719142555_32702.png'
    },
    handleUploadSuccess(file) {
      this.form.cover_info = [file.full_path]
    }
  },
  created() {
    this.infoData()
    this.getBrand()
    this.getUser()
  }
}
</script>
<style lang='scss'>
.title {
  font-size: 18px;
  font-weight: 800;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield; /* 此处写不写都可以 */
}

.row-switch {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: flex-start
}
</style>
